<template>
  <section>
    <v-card :loading="loading">
      <v-container style="margin-top: 52px;" fluid>
        <v-row>
          <v-col class="col-image" v-if="currentUser.partner.display_mode === 'shop'"
             @dragenter.prevent="handleDragEnter"
             @dragover.prevent="handleDragOver"
             @dragleave="handleDragLeave"
             @drop.prevent="handleDrop"
          >
            <v-sheet
              :elevation="isDragging ? 4 : 1"
              class="d-flex align-center justify-center position-relative"
              :style="{ width: '200px', height: '200px', position: 'relative', margin: '20px'}"
            >
              <v-img
                v-if="category.image || image"
                :src="image ? image : categoryBaseUrl + category.image"
                width="200px"
                height="200px"
                contain
              >
              <div class="overlay-buttons">
                <v-btn
                  @click="openFileDialog"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  @click="deleteImage"
                  color="error"
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
              </v-img>
              <v-btn
                v-else
                @click="openFileDialog"
                color="primary"
                icon
                class="add-button"
              >
                <v-icon>mdi-image-plus</v-icon>
              </v-btn>
            </v-sheet>
            <input
              ref="fileInput"
              type="file"
              accept="image/*"
              style="display: none"
              @change="handleFile"
            />
          </v-col>
          <v-col>
            <v-card-title>
              <v-flex sm22 md10 mt5 v-if="mode !== 'edit'" style="margin-bottom: 20px;">
                {{ $t('Create new category') }}
              </v-flex>

              <v-layout v-if="mode === 'edit'">
                <v-flex sm22 md10 mt5>
                  <v-text-field v-model="category.name" :label="$t('Category name')" />
                </v-flex>
                <v-spacer class="mobile"/>
                <v-flex sm4 xs4 md4>
                  <v-switch label="Enabled" v-model="category.is_active" style="margin-top: 0px; float: right;"/>
                </v-flex>
              </v-layout>

              <v-layout class="create-name" v-else>
                <v-flex sm22 md10 mt5>
                  <v-text-field v-model="category.name" :label="$t('Category name')" />
                </v-flex>
                <v-spacer class="mobile"/>
                <v-flex sm4 xs4 md4>
                  <v-switch label="Enabled" v-model="category.is_active" style="margin-top: 0px; float: right;"/>
                </v-flex>
              </v-layout>
            </v-card-title>

            <v-layout class="px-4">
              <v-flex>
                <v-text-field v-model="category.position" :label="$t('Position Category')" />
              </v-flex>
            </v-layout>

            <v-layout class="px-4">
              <v-flex class="age-restricted">
                <span class="mr-2 age-restricted-title">{{ $t('Age restricted') }}</span>
                <v-checkbox v-model="category.category_age_restriction"/>
              </v-flex>
            </v-layout>

            <v-layout class="px-4">
              <v-flex class="time-range" md12 xs12>
                <div class="time-range-checkbox__root">
                  <span class="mr-2 time-range-title">{{ $t('Set the time range') }}</span>
                  <v-checkbox hide-details class="time-range-checkbox" v-model="category.availability_time_enable"/>
                </div>
                <small class="small-time-range-text">{{ $t('* category will be active only for the specified time range') }}</small>
              </v-flex>
            </v-layout>

            <v-layout class="px-4 mb-2 time-range-block">
              <v-flex style="display: flex;" md6 xs12 v-if="category.availability_time_enable">
                <v-select class="mr-5" :items="daysInWeek" :label="$t('From the day')" v-model="category.availability_days_from" item-text="title" item-value="value"/>
                <v-select :items="daysInWeek" :label="$t('To the day')" v-model="category.availability_days_to" item-text="title" item-value="value"/>
              </v-flex>
              <v-flex style="display: flex;" md6 xs12 v-if="category.availability_time_enable">
                <v-flex md6 xs6>
                  <v-text-field type="time" :prefix="$t('from', {symbol: ''})" v-model="category.availability_hours_from" class="mx-5 mt-0"/>
                </v-flex>
                <v-flex md6 xs6>
                  <v-text-field type="time" :prefix="$t('to', {symbol: ''})" v-model="category.availability_hours_to" class="mx-5 mt-0"/>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>

      <v-expansion-panels class="category-edit-form__product-table-expand" v-model="productsExpanded" focusable>
        <v-expansion-panel>
          <v-expansion-panel-header><v-icon class="mr-3 flex-none" color="red" v-if="category.product_count === 0">mdi-alert</v-icon> {{ $t('Category Products') }}</v-expansion-panel-header>
          <v-expansion-panel-content class="category-products-list">
            <products-list v-if="mode === 'edit' && productsExpanded === 0" :category-id="categoryId" :showSort="true"/>
            <v-flex v-else-if="productsExpanded === 0" style="padding: 20px 10px;">
              {{ $t('You will be able to add products when category will get created') }}
            </v-flex>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card-actions>
        <v-btn v-if="mode === 'edit'" class="white--text pl-3" color="red" depressed text dark @click="openDeleteModal">
          {{ $t('Delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="white--text mr-4 my-3" color="primary" depressed text @click="submit">
          {{ mode === 'edit' ? $t('Save') : $t('Create') }}
        </v-btn>
        <v-btn v-if="mode === 'new'" class="mr-2 mt-0 p-0 white--text" color="red" depressed text @click="close">
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <category-delete-modal ref="categoryDeleteModal" :category="category" />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ProductsList from '@/components/Catalog/Categories/CategoryEditForm/ProductsList'
import CategoryDeleteModal from '@/components/Catalog/Categories/CategoryEditForm/CategoryDeleteModal'

export default {
  name: 'CategoryEditForm',
  components: {
    ProductsList,
    CategoryDeleteModal
  },
  props: {
    categoryId: {
      type: Number,
      required: true
    },
    mode: {
      type: String,
      default: () => 'edit'
    }
  },
  data () {
    return {
      loading: false,
      categoryBaseUrl: process.env.VUE_APP_BASE_IMAGE_URL + '/catalog/category/',
      imageFile: null,
      image: null,
      clearImage: false,
      isDragging: false,
      category: {availability_hours_from: null},
      productsExpanded: false,
      daysInWeek: [
        {
          title: this.$t('Monday'),
          value: 'monday'
        },
        {
          title: this.$t('Tuesday'),
          value: 'tuesday'
        },
        {
          title: this.$t('Wednesday'),
          value: 'wednesday'
        },
        {
          title: this.$t('Thursday'),
          value: 'thursday'
        },
        {
          title: this.$t('Friday'),
          value: 'friday'
        },
        {
          title: this.$t('Saturday'),
          value: 'saturday'
        },
        {
          title: this.$t('Sunday'),
          value: 'sunday'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUser',
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      loadCategory: 'catalog/category/loadCategory',
      createCategory: 'catalog/category/create',
      updateCategory: 'catalog/category/save',
      uploadImage: 'catalog/category/uploadImage',
      removeImage: 'catalog/category/removeImage',
    }),
    handleDragEnter() {
      this.isDragging = true;
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    handleDragLeave() {
      this.isDragging = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;

      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.fileChange(files[0]);
      }
    },
    handleFile(event) {
      event.preventDefault();
      const files = event.target.files;
      if (files.length > 0) {
        this.fileChange(files[0]);
      }
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    fileChange(file) {
      this.clearImage = false;
      this.imageFile = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    deleteImage() {
      this.clearImage = true;
      this.image = null;
      this.imageFile = null;
      this.category.image = null;
    },
    submit () {
      if (this.mode === 'edit') {
        this.save()
      } else {
        this.create()
      }
    },
    save () {
      let category_id = this.category.entity_id ? this.category.entity_id : this.category.id
      this.updateCategory({ category_id, category: this.category, token: this.token })
        .then(res => {
          if (this.image && this.imageFile && !this.clearImage) {
            this.imageSave(res.category.entity_id)
          } else if (this.clearImage) {
            this.imageRemove(res.category.entity_id)
          }
          this.$store.dispatch('notification/spawnNotification', {
            type: 'success',
            message: this.$t('Category {categoryName} has been saved successfully.', {categoryName: this.category.name}),
            action1: { label: this.$t('OK') }
          })
        })
        .catch(err => {
          console.error('Something went wrong: ' + err)
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Something went wrong: ') + err,
            action1: { label: this.$t('OK') }
          })
        })
        .finally(() => {
          this.loading = false
          this.close()
        })
    },
    create () {
      this.loading = true
      this.createCategory({ category: this.category, token: this.token })
        .then(res => {
          if (this.image && this.imageFile) {
            this.imageSave(res.entity_id)
          }
          this.$emit('created', res.entity_id)
          this.$store.dispatch('notification/spawnNotification', {
            type: 'success',
            message: this.$t('Category {categoryName} has been created successfully.', {categoryName: this.category.name}),
            action1: { label: this.$t('OK') }
          })
        })
        .catch(err => {
          console.error('Something went wrong: ' + err)
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Something went wrong: ') + err,
            action1: { label: this.$t('OK') }
          })
        })
        .finally(() => {
          this.loading = false
          this.close()
        })
    },
    imageSave (categoryId) {
      this.uploadImage({ categoryId: categoryId, token: this.token, image: this.imageFile })
        .then(image => {
          this.category.image = image
          this.image = null
          this.imageFile = null
          this.clearImage = false
        })
        .catch(err => {
          console.error('Something went wrong: ' + err)
        })
    },
    imageRemove (categoryId) {
      this.removeImage({ category_id: categoryId, token: this.token })
        .then(() => {
          this.clearImage = false
        })
        .catch(err => {
          console.error('Something went wrong: ' + err)
        })
    },
    close () {
      this.$bus.$emit('close-category-form', this.mode)
    },
    openDeleteModal () {
      this.$refs.categoryDeleteModal.openDialog()
    }
  },
  mounted () {
    if (this.categoryId) {
      this.loading = true
      this.loadCategory({ category_id: this.categoryId, token: this.token })
        .then(category => {
          this.category = category
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .age-restricted {
    display: flex;
    font-size: 16px;
    align-items: center;
    &-title {
      @media (max-width: 1200px) {
        font-size: 15px;
      }
    }
  }
  .center {
    align-items: center;
    justify-content: center;
  }
  .mobile {
    display: none;
    @media (max-width: 1200px) {
      display: block;
    }
  }
  .time-range-block {
    @media (max-width: 1200px) {
      display: block;
    }
  }
  .time-range {
    display: flex;
    align-items: flex-start;
    position: relative;
    place-content: start;
    flex-direction: column;
    @media (max-width: 1200px) {

    }
    &-time {
      @media (max-width: 1200px) {
        margin: 0;
      }
    }
    &-title {
      @media (max-width: 1200px) {
        font-size: 15px;
      }
    }
    .small-time-range-text {
      color: #808080;
      @media (max-width: 1200px) {
        font-size: 11px;
      }
    }

    &-checkbox {
      margin: 0;
      padding: 0;

      &__root {
        display: flex;
      }
    }
  }
  .end-xs {
    @media (max-width: 1200px) {
      text-align-last: end;
    }
  }
  .create-name {
   @media (max-width: 500px) {
     display: block;
   }
  }
  .flex-none {
    flex: none;
  }
  .col-image {
    display: contents;
  }
  .col-image:hover .overlay-buttons{
    opacity: 1;
    button {
      transform: scale(1.3);
    }
  }
  .overlay-buttons {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    padding: 5px;
    transition: opacity 0.4s ease-in-out;
  }
  .add-button {
    width: 100%;
    height: 100%;
  }

  .category-edit-form {
    &__product-table-expand {
      padding: 0 20px;
    }
  }
</style>

<style lang="scss">
.category-products-list {
  .v-expansion-panel-content {
    &__wrap {
      padding: 0;
    }
  }
}
</style>
